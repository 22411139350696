import React from "react";
import {InfoIcon, LogsIcon} from 'components/icons';
import {MeContext} from "meContext";
import {LogsShow} from "components/LogsShow";
import {FormDataContext} from "../FormDataContext";
import FormFieldsShow from "components/FormFieldsShow";
import { rawListeners } from "superagent";

export function uniqTabs(oldTabs) {
    return (oldTabs || [])
        .map(i => 'key:'.concat(i.listTitle, i.apiName))
        .join(';')
}

export default function useListTabs(topColsBlocks, props) {
    const {item, subItems, colsInTab, parent, showJournalUser, ...topItem} = props
    const {cols, values} = React.useContext(FormDataContext);
    // const cols = colsDocRequestExample;
    // console.log('callfunction useListTabs',cols)


    const [tabs, setTabs] = React.useState(null);

    const {roles}     = React.useContext(MeContext);

    // console.log('subItems', subItems)

    React.useEffect(() => {
    const defaultTabComponent = tabNum => props => <FormFieldsShow
        {...props}
            fieldsShow={cols}
        topColsBlocks={topColsBlocks.filter(i => i.num === tabNum)}
    />;

    const mainTab = {
        listTitle: 'Основное',
        icon:      <InfoIcon/>,
        apiName:   'edit',
        Component: props => <FormFieldsShow
            {...props}
                fieldsShow={cols}
            topColsBlocks={topColsBlocks.filter(i => i.num && !i.colsInTab)}
        />,
    };

        const logTab = roles && roles.includes('ROLE_DEBUG') && item && item.guid &&
        {
            listTitle: 'Журнал (админ)',
            Component: LogsShow,
            icon:      <LogsIcon/>,
            subItems: subItems,
            admin: true,
        }
        const logUserTab = item && item.guid &&
        {
            listTitle: 'Журнал',
            Component: LogsShow,
            icon:      <LogsIcon/>,
            subItems: subItems,
            admin: false
        }
        // console.log({item})
        // console.log('subItems 1');

        
        Promise.resolve(
            'function' === typeof subItems
                ? subItems(values || {debug:'none'}, topItem)
                : subItems
        )
        .then(items => {
            const newTabsList = [
                mainTab,
                ...topColsBlocks
                    .filter(i => i.colsInTab)
                    .map(i => {
                        return {
                            listTitle: i.title || '--',
                            Component: i.component || defaultTabComponent(i.num),
                            icon:      i.icon || <InfoIcon/>,
                            apiName:   i.apiName,
                            item:      i,
                        }
                    }),
                ...(items && items.list || []),
                showJournalUser 
                && {
                    ...logUserTab,
                    subItems
                },
                logTab && {
                    ...logTab,
                    subItems
                },
            ]
                    .filter(v=>v);
                // console.log('setTabs');
                setTabs(oldTabs => uniqTabs(oldTabs) === uniqTabs(newTabsList) ? oldTabs : newTabsList )
            })
            
        //ToDo убрать привязку к item
    }, [values])
    // console.log('setTabs return',tabs);
    return tabs;
}