import {
    AppBar,
    Avatar,
    Badge,
    CssBaseline,
    Grid,
    IconButton,
    Link,
    ListItemIcon,
    MenuItem,
    SwipeableDrawer,
    Toolbar,
    Tooltip,
    Typography,
    Box,
} from '@material-ui/core';
import {
    Build as RightsIcon,
    ExitToAppOutlined as ExitIcon,
    Group,
    MoreVert as MoreIcon,
    Settings as SettingsIcon,
} from '@material-ui/icons';
import {CancelIcon, MenuIcon, NotificationsIcon} from 'components/icons';
import {MenuTree} from 'components/Menu';
import MyProfile from 'components/MyProfile';
import RoleSelector from 'components/RoleSelector';
import {ReturnUser, SwitchUser} from 'components/SwitchUser';
import moment from 'moment';
import React, {useState} from 'react';

import useStyles from 'styles';
import AdminShow from './FieldFormat/AdminShow';
import {ApiDataLoad} from "components/ApiDataLoad";
import {MeContext} from "meContext";
import {withStyles} from "@material-ui/core/styles";
import {useHistory} from 'react-router-dom'
import WebSocketEvents from "./WebSocketEvents"
import {Chat} from "components/Chat/Chat";
import {Help} from "./Help/Help";


export default function AppLayout(props) {

    const [isSyncProblem, setIsSyncProblem]  = useState(0);
    const refAppBar = React.useRef(null)

    React.useEffect(() => {
        const now = new Date();

        ApiDataLoad('/api/syncs', {
            enabled: true,
            lastRunDate: {
                before: moment()
                            .subtract(15, 'minutes')
                            .utc().format(),
            },
        }).then(
            data => setIsSyncProblem(data)
        );
    }, []);



    const classes          = useStyles();
    const [open, setOpen]  = useState(false);
    const [userOptionsOpen, setUserOptionsOpen] = useState(false);
    const history = useHistory()

    const handleDrawerOpen = () => {

        setUserOptionsOpen(false);
        setOpen(!open);
    };

    const handleuserOptionsOpen = () => {
        setOpen(false);
        setUserOptionsOpen(!userOptionsOpen);
    }

    const me = React.useContext(MeContext);

    const {roles: myRoles} = me || {};

    const [anchorEl, setAnchorEl]     = React.useState(null);
    const [openOption, setOpenOption] = React.useState(null);

    const openMenu = openOption === 'menu';

    const settingsUser = () => {
        handleuserOptionsOpen()
        history.push(`/my/users/${me.guid}/edit`)
    }

    const settingsProfile = () => {
        handleuserOptionsOpen()
        history.push(`/my/settings`)
    }

    const onMenuOpen = name => (event) => {
        setAnchorEl(event.currentTarget);
        setOpenOption(name);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenOption(null);
    };

    const TypographyPhone = withStyles(() => (
        {
            root: {
                fontSize: "1.6rem"
            },
        }
    ))(Typography);


    return <React.Fragment>
        <CssBaseline/>
        <AppBar position="fixed" className={classes.appBar} ref={refAppBar}>
            <Toolbar>
                <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                >
                    {open ? <CancelIcon/> : <MenuIcon/>}
                </IconButton>
                <img alt='' src="https://transport.foamline.com/local/templates/main/images/logoFL.png"/>
                &nbsp; &nbsp; &nbsp;
                <Typography variant="h6" noWrap>
                    TMS Транспортный портал
                </Typography>
                <div className={classes.grow}/>
                <Grid className={classes.mr_1}>
                    <TypographyPhone noWrap>
                        +7(495)660-41-51
                    </TypographyPhone>
                    <Link href="/docu/Пользовательское соглашение.doc" variant="body2" color="inherit">
                        {'Пользовательское соглашение'}
                    </Link>

                </Grid>

                <div className={classes.sectionDesktop}>

                    {/*<AccountCircle />*/}

                    <Grid container
                          spacing={4}
                          alignItems="baseline"
                          direction={'row'}
                          justify="center"
                    >
                        <Grid item><RoleSelector/></Grid>
                    </Grid>

                </div>
                <Box mx={1} ml={1.5}>
                    <div className={classes.sectionMobile}>
                        <IconButton
                                aria-label="show more"
                                // aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                // onClick={handleMobileMenuOpen}
                                color="inherit"
                        >
                            <MoreIcon/>
                        </IconButton>
                    </div>
                    <AdminShow alert>
                        {isSyncProblem.length > 0 &&
                        <Tooltip title={<React.Fragment>Проблемы с синхронизацией: {isSyncProblem.map((i, key) =>
                                <div key={key}>{i.name}</div>)}</React.Fragment>}
                        >
                            <IconButton
                                    href={"/soap/sync"}
                                    color="inherit"
                                    aria-label="open drawer"
                                    // onClick={handleOpen}
                                    edge="start"
                                    // className={classes.ml_1}
                            >
                                <Badge badgeContent={isSyncProblem.length} color="error">
                                    <NotificationsIcon color={'error'}/>
                                </Badge>
                            </IconButton>
                        </Tooltip>
                        }
                    </AdminShow>
                    <WebSocketEvents/>
                    <Help/>
                    <Chat/>

                </Box>

                <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleuserOptionsOpen}
                        edge="start"

                >
                    <Avatar alt={me.username}
                            src={me.picture.includes('/img/') ? `/upload/${me.picture}` : me.picture}/>
                </IconButton>
            </Toolbar>
        </AppBar>
        {/*<nav className={classes.drawer} aria-label="mailbox folders">*/}
        {/*<Hidden xsDown implementation="css">*/}
        <SwipeableDrawer
                onOpen={() => {
                }}
                classes={{
                    paper: classes.drawerPaper,
                    docked: classes.drawerBackdrop,
                }}
                // variant="permanent"
                onClose={handleDrawerOpen}
                open={open}
                style={{top: refAppBar.current ? refAppBar.current.offsetHeight : '0'}}
                ModalProps={{
                    BackdropProps: {
                        classes: {
                            root: classes.drawerBackdrop
                        }
                    }
                }}
        >
            <MenuTree onClick={handleDrawerOpen} roles={myRoles}/>

        </SwipeableDrawer>

        <SwipeableDrawer
                onOpen={() => {
                }}
                classes={{
                    paper: classes.drawerPaper,
                }}
                // variant="permanent"
                onClose={handleuserOptionsOpen}
                open={userOptionsOpen}
                anchor='right'
                style={{top: refAppBar.current ? refAppBar.current.offsetHeight : '0'}}
                ModalProps={{
                    BackdropProps: {
                        classes: {
                            root: classes.drawerBackdrop
                        }
                    }
                }}
        >
            <MyProfile/>

            <MenuItem onClick={settingsUser}>
                <ListItemIcon><SettingsIcon/></ListItemIcon>
                <span className={classes.avatar_text_link}>Настройки пользователя</span>
            </MenuItem>
            {/* <MenuItem onClick={onMenuOpen('settings')}>
                <ListItemIcon><SettingsIcon/></ListItemIcon>
                 <span className={classes.avatar_text_link}>Настройки пользователя</span>
            </MenuItem> */}
            <MenuItem onClick={settingsProfile}>
                <ListItemIcon><RightsIcon/></ListItemIcon>
                <span className={classes.avatar_text_link}>Настройки профиля</span>
            </MenuItem>
            {me.is_admin &&
            <MenuItem onClick={onMenuOpen('SwitchUser')}>
                <ListItemIcon><Group/></ListItemIcon>
                <span className={classes.avatar_text_link}>Войти как...</span>

            </MenuItem>
            }
            {me.roles.includes('ROLE_PREVIOUS_ADMIN') &&
            <MenuItem onClick={onMenuOpen('ReturnUser')}>
                <ListItemIcon><Group/></ListItemIcon>
                <span className={classes.avatar_text_link}>Вернуться...</span>

            </MenuItem>
            }
            <MenuItem onClick={() => {
                window.location.replace('/connect/logout');
            }}>
                <ListItemIcon><ExitIcon/></ListItemIcon>
                <span className={classes.avatar_text_link}>Выход</span>
            </MenuItem>
            {openOption === 'SwitchUser' && <SwitchUser onClose={handleClose}/>}
            {openOption === 'ReturnUser' && <ReturnUser onClose={handleClose}/>}
        </SwipeableDrawer>
        {/*</Hidden>*/}
        {/*</nav>*/}
    </React.Fragment>;
}